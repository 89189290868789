<template>
  <div id="node">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <i class="fas fa-code-branch"></i>
          {{node.name}} <small>({{node.mac}})</small>
          <button type="button" class="button is-small is-success" style="float:right;" @click="new_device=true">
            <span class="mr-2 is-hidden-mobile">Create Device</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <div class="blobs-container" style="float:right;">
            <div class="blob" :class="mqtt_status"></div>
          </div>
          <ul>
            <li><a @click="redirect('/member/mornitor')">Plants</a></li>
            <li><a @click="redirect('/member/branch/'+node.b_id)">{{branch.name}}</a></li>
            <li class="is-active"><a>{{node.name}} <small class="ml-1 is-size-7">({{node.mac}})</small></a></li>
          </ul>
        </nav>
        <div class="has-text-centered" v-if="!Devices.length">
          <hr>You didn't create any device!
        </div>
        <div class="columns is-multiline" v-else>
          <div class="column is-one-third-desktop" v-for="(val,key) in Devices" :key="key">
            <article class="message" :class="{'is-success':parseInt(val.enable),'is-danger':!parseInt(val.enable)}">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer" @click="redirect('/member/device/'+val.id)">
                  <span v-if="val.type==1">
                    <i class="fas fa-solar-panel"></i>
                  </span>
                  <span v-if="val.type==2">
                    <i class="fas fa-charging-station"></i>
                  </span>
                  Device{{val.number}} <small v-if="val.detail">({{val.detail}})</small>
                </p>
                <div class="dropdown is-right is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button is-xs">
                      <i class="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div class="dropdown-menu">
                    <div class="dropdown-content">
                      <a class="dropdown-item" @click="device=val; new_device=true;">
                        Edit/ Update
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-body has-text-left py-2 is-size-6 is-size-7-mobile cursor_pointer" v-if="refresh_d_id!==val.id" @click="redirect('/member/device/'+val.id)">
                <!-- <pre>{{val}}</pre> -->
                <div v-if="parseInt(val.type)==1">
                  <small v-if="!val.log_created">Not found any data</small>
                  <div v-else>
                    <p >Current : {{val.Current_a}} A</p>
                    <p >Total Power: {{val.Power}} W</p>
                    <p >Power Factor: {{val.PF}}</p>
                    <p >Imp Energy: {{val.Imp_Energy}} Kwh</p>
                    <p >Exp Energy: {{val.Exp_Energy}} Kwh</p>
                    <p >Total Energy: {{val.Total_Energy}} Kwh</p>
                  </div>
                </div>
                <div v-if="parseInt(val.type)==2">
                  <small v-if="!val.log_created">Not found any data</small>
                  <div v-else>
                    <p >PV1-5 voltage : {{val.PV_voltage}} A</p>
                    <p >PV1-5 current: {{val.PV_current}} W</p>
                    <p >PV1-5 Power: {{val.PV_power}}</p>
                    <p >Output active power: {{val.Output_active_power}} Kwh</p>
                    <p >Output reactive power: {{val.Output_reactive_power}} Kwh</p>
                    <p >Grid frequency: {{val.Grid_frequency}} Kwh</p>
                  </div>
                </div>
              </div>
              <div class="message-body p-0" v-else>
                <button class="button p-6 w-100 has-background-success-light is-loading"></button>
              </div>
              <!-- has-text-centered -->
              <div class="message-footer is-size-6 is-size-7-mobile px-5" v-if="refresh_d_id!==val.id">
                <small v-if="val.log_created">
                  <span class="has-text-link">Last Update: {{val.log_created | date_data}}</span>
                </small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <modal-device :show="new_device" :mac="node.mac" :number="next_number" :device="device" @submit="submit_device" @close="new_device=false; device={};"></modal-device>

  </div>
</template>

<script>
import ModalDevice from '@/components/Modal/Device'

// var alertUpdate = new Audio(
//   require("@/assets/update_log.wav")
// );
// alertUpdate.play();

export default {
  name: 'MemberNode',
  components:{ ModalDevice },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
    connected: Boolean,
    // topic: String,
    topic: Array,
  },
  data(){
    return {
      n_id: this.$route.params.id,
      branch: {},
      node: {},
      device: {},
      new_device: false,
      refresh_d_id: 0,
    }
  },
  created(){
    const INDEX = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.n_id));
    if(INDEX!==-1){
      this.node = this.nodes[INDEX];

      const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
      this.branch = this.branchs[INDEX_B];
    }else{
      this.redirect("/member/mornitor")
    }
  },
  mounted(){
    // console.log(this.loaded.get_device);
    if(!this.loaded.get_device){
      this.$emit('get_device',this.n_id);
    }else{
      const INDEX_ = this.loaded.get_device.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.n_id));
      if(INDEX_==-1){
        this.$emit('get_device',this.n_id);
      }
    }

    if(this.node.mac){
      if(this.client){
        this.$emit("subscribeMQTT",this.node.mac);
      }else{
        this.$emit("connectMQTT",this.node.mac);
      }
      this.get_device_log_for_each_devices();
    }
    // setTimeout(() => this.updateLastedLog(), 3000);
  },
  methods:{
    get_device_log_for_each_devices(){
      if(!this.Devices.length){
        setTimeout(() => {
          this.get_device_log_for_each_devices();
        }, 500);
        return false;
      }

      this.Devices.forEach((e,i) => {
        if(!this.loaded.get_device_log){
          setTimeout(() => {
            this.$emit('get_device_log', e.id, e.type);
          }, (i+1)*500);
        }else{
          const INDEX_ = this.loaded.get_device_log.map((d_id) => d_id).indexOf(e.id);
          if(INDEX_==-1){
            setTimeout(() => {
              this.$emit('get_device_log', e.id, e.type);
            }, (i+1)*500);
          }
        }
      });
    },
    updateLastedLog(device_log){
      this.devices.forEach((e) => {
        let thisDeviceLog = device_log.filter((log) => {
          // console.log(log,log.d_id,e.id,log.d_id==e.id, e.Current_a, e.PV_voltage);
          return log.d_id==e.id
        });

        if(!thisDeviceLog.length)return false;

        // ------------ ใช้ไม่ได้ ------------
        // if(parseInt(thisDeviceLog.type)==1){
        //   // console.log(thisDeviceLog[0],e.id);
        //   const IMP_ENERGY = (thisDeviceLog[0].Imp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Imp_Energy) : parseInt(0);
        //   const EXP_ENERGY = (thisDeviceLog[0].Exp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Exp_Energy) : parseInt(0);
        //
        //   e.Power = (thisDeviceLog[0].Power_t!=="-")?thisDeviceLog[0].Power_t:thisDeviceLog[0].Power_a;
        //   e.PF = (thisDeviceLog[0].PF_t!=="-")?thisDeviceLog[0].PF_t:thisDeviceLog[0].PF_a;
        //
        //   e.Current_a = thisDeviceLog[0].Current_a;
        //   e.Power_t = thisDeviceLog[0].Power_t;
        //   e.PF_t = thisDeviceLog[0].PF_t;
        //   e.Imp_Energy = thisDeviceLog[0].Imp_Energy;
        //   e.Exp_Energy = thisDeviceLog[0].Exp_Energy;
        //   e.Total_Energy = IMP_ENERGY + EXP_ENERGY;
        //
        //   if(thisDeviceLog[0].Current_b!=="-" && thisDeviceLog[0].Current_c!=="-"){
        //     let Current_a = parseFloat(thisDeviceLog[0].Current_a) + parseFloat(thisDeviceLog[0].Current_b) + parseFloat(thisDeviceLog[0].Current_c);
        //     e.Current_a = parseFloat(Current_a).toFixed(2);
        //   }
        // }else if(parseInt(thisDeviceLog.type)==2){
        //   e.PV_voltage = thisDeviceLog[0].PV_voltage;
        //   e.PV_current = thisDeviceLog[0].PV_current;
        //   e.PV_power = thisDeviceLog[0].PV_power;
        //   e.Output_active_power = thisDeviceLog[0].Output_active_power;
        //   e.Output_reactive_power = thisDeviceLog[0].Output_reactive_power;
        //   e.Grid_frequency = thisDeviceLog[0].Grid_frequency;
        // }
        // ------------ ใช้ไม่ได้ ------------
        const IMP_ENERGY = (thisDeviceLog[0].Imp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Imp_Energy) : parseInt(0);
        const EXP_ENERGY = (thisDeviceLog[0].Exp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Exp_Energy) : parseInt(0);

        e.Power = (thisDeviceLog[0].Power_t!=="-")?thisDeviceLog[0].Power_t:thisDeviceLog[0].Power_a;
        e.PF = (thisDeviceLog[0].PF_t!=="-")?thisDeviceLog[0].PF_t:thisDeviceLog[0].PF_a;

        e.Current_a = thisDeviceLog[0].Current_a;
        e.Power_t = thisDeviceLog[0].Power_t;
        e.PF_t = thisDeviceLog[0].PF_t;
        e.Imp_Energy = thisDeviceLog[0].Imp_Energy;
        e.Exp_Energy = thisDeviceLog[0].Exp_Energy;
        e.Total_Energy = IMP_ENERGY + EXP_ENERGY;

        if(thisDeviceLog[0].Current_b!=="-" && thisDeviceLog[0].Current_c!=="-"){
          let Current_a = parseFloat(thisDeviceLog[0].Current_a) + parseFloat(thisDeviceLog[0].Current_b) + parseFloat(thisDeviceLog[0].Current_c);
          e.Current_a = parseFloat(Current_a).toFixed(2);
        }

        // e.log_created = thisDeviceLog[0].created;
        // console.log(thisDeviceLog[0].created,thisDeviceLog[0].date_now);
        var d = new Date(parseInt(thisDeviceLog[0].date_now)),
        dformat = [d.getMonth()+1,
                  d.getDate(),
                  d.getFullYear()].join('-')+' '+
                  [d.getHours(),
                  d.getMinutes(),
                  d.getSeconds()].join(':');
        // console.log(thisDeviceLog[0].date_now,dformat);
        e.log_created = dformat;

        this.refresh_d_id = e.id;
      });
    },
    submit_device(input){
      const INDEX = this.devices.map((d) => d.number).indexOf(input.number);
      if(!input.id){
        if(INDEX!==-1){
          this.$swal({
            title: "Can't create device!",
            text: "You already have this number \n (Device Number: "+input.number+")",
            className: "text-center",
            icon: "warning",
          });
        }else{
          this.submit_new_device(input);
          this.new_device = false;
        }
      }else{
        if(INDEX!==-1 && input.number!==this.device.number){
          this.$swal({
            title: "Can't create device!",
            text: "You already have this number \n (Device Number: "+input.number+")",
            className: "text-center",
            icon: "warning",
          });
        }else if(input.number!==this.device.number){
          this.$swal({
            title: "Change device number?",
            text: "Are you sure you want to change device number \n\n (from: "+this.device.number+") \n (to: "+input.number+")",
            className: "text-center",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              this.submit_edit_device(input);
              this.new_device = false;
            }
          });
        }else{
          this.submit_edit_device(input);
          this.new_device = false;
        }
      }
    },
    async submit_new_device(input){
      var url = this.api+'device/add/'+this.secret;
      var data = new FormData();
      data.set('n_id', this.n_id);
      data.set('number', input.number);
      data.set('detail', input.detail);
      data.set('type', input.type);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          this.devices.push(response.data.data);
          this.$swal("","Created Device","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    async submit_edit_device(input){
      var url = this.api+'device/save/'+this.secret;
      var data = new FormData();
      Object.keys(input).forEach((key) => {
        data.set(key, input[key]);
      });
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.device[key] = input[key];
          });
          this.device = {};
          this.$swal("","Successful Save","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
  },
  computed:{
    mqtt_status(){
      return (!this.connected) ? "red" : "green"
    },
    Devices(){
      return this.devices.filter(d => { return parseInt(d.n_id)==parseInt(this.n_id) });
    },
    next_number(){
      if(this.Devices.length){
        return Math.max.apply(null, this.Devices.map(d => d.number)) + 1;
      }else{
        return 1;
      }
    }
  },
  watch:{
    device_log(val){
      this.updateLastedLog(val);
    },
    refresh_d_id(val){
      if(val)setTimeout(() => this.refresh_d_id=0, 1000);
    }
  }
}
</script>

<style scoped="">
.blobs-container {
  display: flex;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 15px;
  width: 15px;
  margin-top: 5px;
  transform: scale(1);
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>


<!--
Operating state

PV1-5 voltage
PV1-5 current
PV1-5 Power
Output active power
Output reactive power
Grid frequency
A-phase voltage
A-phase current
B-phase voltage
B-phase current
C-phase voltage
C-phase current
Total production
Total generation time
Today production
Today generation time
-->

<!--
tnj-electronics/powermeter999/${mac}
{
   "ID": "1",
   "PV_voltage": "123",
   "PV_current": "123",
   "PV_power": "123",
   "Output_active_power": "123",
   "Output_reactive_power": "123",
   "Grid_frequency": "123",
   "A_phase_voltage": "123",
   "A_phase_current": "123",
   "B_phase_voltage": "123",
   "B_phase_current": "123",
   "C_phase_voltage": "123",
   "C_phase_current": "123",
   "Total_production": "123",
   "Total_generation_time": "123",
   "Today_production": "123",
   "Today_generation_time": "123",
   "Key": "6c6756c82eca0bdd4c1340fa24d4f6081e906aeb",
  "Inverter": "1",
 }
 -->

 <!--
tnj-electronics/powermeter999/a3f49de3
 {"ID": "1","PV_voltage": "111.90","PV_current": "0.62","PV_power": "0.06","Output_active_power": "0.06","Output_reactive_power": "0.00","Grid_frequency": "49.93","A_phase_voltage": "232.20","A_phase_current": "0.35","B_phase_voltage": "-","B_phase_current": "-","C_phase_voltage": "-", "C_phase_current": "-","Total_production": "415","Total_generation_time": "1085","Today_production": "1.86","Today_generation_time": "225","Key": "6c6756c82eca0bdd4c1340fa24d4f6081e906aeb","Inverter": "1"}
-->
